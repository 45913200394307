import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from './../components/portfolio-banner/PortfolioBanner'
import PortfolioHeader from './../components/portfolio-header/PortfolioHeader'
import Text from './../components/text/Text'
import Apply from './../components/forms/apply/Apply'
import SEO from '../components/seo'

class PostTemplate extends Component {
  constructor(props) {
    super(props)
  }

  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-245px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressWpJob
    const homepage = this.props.data.wordpressSiteMetadata.home

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <PortfolioHeader
            title={currentPage.title}
            city={currentPage.excerpt}
          />
          <Text data={currentPage.content} />
          <Apply
            title="Aplikuj na stanowisko"
            form={this.props.data.wordpressWpJob.acf.contactCode}
            jobName={this.props.data.wordpressWpJob.title}
            home={homepage}
          />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressWpJob(id: { eq: $id }) {
      title
      content
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
      acf {
        contactCode
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`
