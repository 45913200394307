import React from "react"
import arrow from "./../../../images/arr-file.svg"

console.log(arrow) // /arr-file.svg

function Arrow() {
  // Import result is the URL of your image
  return <img src={arrow} alt="icon" />
}

export default Arrow