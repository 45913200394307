import React, { Component } from 'react';
import ModalMessage from './../../modal-message/ModalMessage';
import Arrow from './InputArrow';



class Apply extends Component {

constructor(props) {
super(props);
this.state = {
yourName: '',
email: '',
phone: '',
cv: '',
letter: '',
cvFile: '',
letterFile: '',
visibleModal: false,
message: '',
placeholderCV: 'Dodaj CV',
placeholderLetter: 'Dodaj list motywacyjny',
cvData: '',
letterData: '',
}
this.checkFile = this.checkFile.bind(this);
this.compileCvToBin = this.compileCvToBin.bind(this);
this.compileLetterToBin = this.compileLetterToBin.bind(this);
}
handleName(event) {
    this.setState({yourName: event.target.value});
}

handleEmail(event) {
    this.setState({email: event.target.value});
}

handlePhone(event) {
    this.setState({phone: event.target.value});
}



checkFile(event, fileList) {
    let fileTypes = event.target.accept.split(',');
    let filename = event.target.files[0].name;
    let fileTypeSecurity = (() => {
        let secondLevelValidation = (() => {
            //szukaj kropek wez ostatnia w nazwie pliku, i potem od tej kropki pobierz rozszerzenie
            let  keyWord = filename.lastIndexOf('.');
            let fileType = filename.slice(keyWord)
            if(fileType === '.pdf' || fileType === '.doc') {
                return true;
            } else {
                return false;
            }
        })()
        if(secondLevelValidation) {
            if(fileTypes.length === 2 && fileTypes[0] === '.pdf' && fileTypes[1] === '.doc' ) {
                let files = event.target.files[0];
                this.setState({type: event.target.value});
                switch(event.target.name) {
                    case 'cv':
                    this.setState({
                        cv: event.target.value, 
                        cvFile: files,
                        placeholderCV: filename.substring(0, 18) + '...'
                    });
                    break;
                    case 'list':
                    this.setState({
                        letter: event.target.value,
                        letterFile: files,
                        placeholderLetter: filename
                    });
                    break;
                    default:
                }
                
                return true;
                
            } else {
                return false;
            }
        }
    })()
}
toogleModal() {
    this.setState( prevState => ({
        visibleModal: !prevState.visibleModal
    }))
}

async compileCvToBin() {
    var readerCv = new FileReader();

    await readerCv.addEventListener('load', () => {
        this.setState ({
            cvData: readerCv.result
        })
    })
   await readerCv.readAsBinaryString(this.state.cvFile)
}
async compileLetterToBin() {
    var readerL = new FileReader();

    await readerL.addEventListener('load', () => {
        this.setState({
            letterData: readerL.result
        })
    })

    await readerL.readAsBinaryString(this.state.letterFile)
}
submitApplication(e) {
    e.preventDefault();

    let sendData = (() => {
        var data = "";
        var boundary = "blob";
        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="cv"; ' + 'filename="'     + this.state.cvFile.name + '"\r\n';
        data += 'Content-Type: ' + this.state.cvFile.type + '\r\n';
        data += '\r\n';
        data += this.state.cvData + '\r\n';

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="'         + 'list'          + '"; ' + 'filename="'     + this.state.letterFile.name + '"\r\n';
        data += 'Content-Type: ' + this.state.letterFile.type + '\r\n';
        data += '\r\n';
        data += this.state.letterData + '\r\n';

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="your-name"\r\n';
        data += '\r\n';
        data += this.state.yourName + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="email"\r\n';
        data += '\r\n';
        data += this.state.email + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="phone"\r\n';
        data += '\r\n';
        data += this.state.phone + "\r\n";

        data += "--" + boundary + "\r\n";
        data += 'content-disposition: form-data; ' + 'name="job-name"\r\n';
        data += '\r\n';
        data += this.props.jobName + "\r\n";

        // close boundary
        data += "--" + boundary + "--";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.props.home + `/wp-json/contact-form-7/v1/contact-forms/${ this.props.form }/feedback`, true);
        xhr.setRequestHeader("Content-Type", 'multipart/form-data; boundary=' + boundary);
        var This = this;
        xhr.send(data);
        xhr.addEventListener('load', function() {
            let data = JSON.parse(xhr.response);
            This.setState({
                visibleModal: true,
                message: data.message,
            });
        });
    })()
  }
render() {

      
return(
<section className="apply">
    <div className="container">
    <ModalMessage message={ this.state.message } visible={ this.state.visibleModal } closeModal={ this.toogleModal.bind(this) } />
        <div className="row">
            <div className="col-12">
                <h3>{ this.props.title}</h3>
            </div>
            <div className="col-12">
                <form action="#" onSubmit={ this.submitApplication.bind(this) }>
                    <label htmlFor="">
                        <input type="text" 
                            placeholder="Imię i Nazwisko" 
                            name="your-name" 
                            value={ this.state.yourName } 
                            onChange={ this.handleName.bind(this) } 
                            autoComplete="off" 
                            required
                        />
                    </label>
                    <label htmlFor="">
                        <input type="text" 
                            placeholder="Numer telefonu" name="phone" 
                            value={ this.state.phone } 
                            onChange={ this.handlePhone.bind(this) } 
                            autoComplete="off" 
                            required
                        />
                    </label>
                    <label htmlFor="">
                        <input type="email" 
                            placeholder="Adres e-mail" 
                            name="email" 
                            value={ this.state.email } 
                            onChange={ this.handleEmail.bind(this) } 
                            autoComplete="off"  
                            required
                        />
                    </label>
                    <label className="file-type" htmlFor="">
                        <div className="fake-file">
                            <p>{this.state.placeholderCV}</p>
                            <span>
                                <Arrow/>
                                <Arrow/>
                                <Arrow/>
                            </span>
                        </div>
                        <input type="file"
                            placeholder={this.state.placeholderCV} 
                            name="cv" accept=".pdf,.doc" 
                            onChange={ (event) => {
                                this.checkFile(event); 
                                this.compileCvToBin();
                                
                            }}
                            required
                        />
                    </label>
                    <label className="file-type" htmlFor="">
                        <div className="fake-file">
                            <p>{this.state.placeholderLetter}</p>
                            <span>
                                <Arrow/>
                                <Arrow/>
                                <Arrow/>
                            </span>
                        </div>
                        <input type="file" 
                            placeholder={this.state.placeholderLetter} 
                            name="list" accept=".pdf,.doc" 
                            onChange={ (event) => {
                                this.checkFile(event);
                                this.compileLetterToBin();
                            }}
                            required
                        />
                    </label>
                    <button className="btn" type="submit">Wyślij</button>
                </form>
                <p><small>
                    Prosimy o umieszczenie w CV klauzuli: Wyrażam zgodę na przetwarzanie moich danych osobowych
                    zawartych w mojej ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
                    (zgodnie z Ustawą z dnia 29.08.1997 roku o Ochronie Danych Osobowych; tekst jednolity: Dz. U. z
                    2002r. nr 101, poz.926 z późn.zm.).
                </small></p>
            </div>
        </div>
    </div>
</section>

)
}
}

export default Apply;

function newFunction(callback) {
    callback && callback();
}
